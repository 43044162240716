import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/spotify.png');

const Component: React.FC<Props> = (props: Props) => {
  const pathname = () => {
    try {
      const parsed = new URL(props.attrs.href);
      return parsed.pathname;
    } catch (err) {
      return "";
    }
  }

  const normalizedPath = pathname().replace(/^\/embed/, "/");

  let height = 300;

  if (normalizedPath.includes("episode") || normalizedPath.includes("show")) {
    height = 232;
  } else if (normalizedPath.includes("track")) {
    height = 80;
  }

  return <iframe title="Spotify Embed" allow="encrypted-media"
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                  frameBorder="0" width="100%" height={`${height}px`} allowFullScreen
      src={`https://open.spotify.com/embed${normalizedPath}`}
    />
}

export default {
  View: Component,
  Regex: new RegExp("https?://open.spotify.com/(.*)$"),
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};