import * as React from "react";
import { useFrontpage } from "../../FrontpageProvider";
import { getCookie, setCookie } from "tiny-cookie";

import TimeAgo from "react-timeago";
import enStrings from 'react-timeago/lib/language-strings/en'
import ruStrings from 'react-timeago/lib/language-strings/ru'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import Note from "../../models/Note";
import { client } from "../../FrontpageApiClient";
import { useEffect } from "react";

const LikeImage = require('../../../../images/like.svg');
const NeutralImage = require('../../../../images/neutral.svg');
const DislikeImage = require('../../../../images/negative.svg');

const formatter = (code) => {
  if(code === 'ru'){
    return buildFormatter(ruStrings);
  }else{
    return buildFormatter(enStrings);
  }
}

interface Props {
  note: Note; 
}

const Feedback: React.FC<Props> = ({note}) => {
  const [vote, setVote] = React.useState<string>(getCookie(`feedback_${note.id}`));

  useEffect(() => setVote(getCookie(`feedback_${note.id}`)), [note.id]);

  const {state, t} = useFrontpage();

  const sendVote = (vote: string) => {
    setVote(vote);
    setCookie(`feedback_${note.id}`, vote, { expires: 365 });

    client.trackFeedback(note.id, vote);
  }

  const handleLike = () => sendVote("like");
  const handleNeutral = () => sendVote("neutral");
  const handleDislike = () => sendVote("dislike");

  const {currentVariant} = state.serverState;

  return <div className="lastupdated has-text-grey">
  <span className="timeago">{t("Last updated")} <TimeAgo date={note.updatedAt} formatter={formatter(currentVariant.lang)}/></span>

  <div className="feedback">
    {vote ?
      <span className="text has-text-grey is-size-7 has-text-weight-semibold is-uppercase">{t("Thank you for your help.")}</span>
      :
      <>
        <span className="text has-text-grey is-size-7 has-text-weight-semibold is-uppercase">{t("Was this page helpful?")}</span>
        <img className="vote" src={LikeImage} onClick={handleLike}/>
        <img className="vote" src={NeutralImage} onClick={handleNeutral}/>
        <img className="vote" src={DislikeImage} onClick={handleDislike}/>
      </>
    }
  </div>
</div>
}

export default Feedback;