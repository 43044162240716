import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/typeform.png');

const Component: React.FC<Props> = (props: Props) => {
  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="100%" height="400px" allowFullScreen
      src={props.attrs.href}
    />
}

export default {
  View: Component,
  Regex: new RegExp("^https://([A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?).typeform.com/to/(.*)$"),
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};