import * as React from "react";
import { Note } from "../../../models";
import { Link } from "react-navi";
import { useFrontpage } from "../../../FrontpageProvider";
import {faCaretDown, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  level: number;
  note: Note,
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
}

const NavLink: React.FC<Props> = ({note, level, onClick}) => {

  const {state} = useFrontpage();
  const {currentVariant, initialNote} = state.serverState;

  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if(!state.currentNote) {
      setExpanded(false);
      return;
    };

    if(state.currentNote.path.indexOf(note.path) !== -1){
      setExpanded(true);
    }
  }, [state.currentNote]);


  const handleExpand = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
  }

  return <li>
    <Link onClick={onClick} href={`/${currentVariant.slug}/notes/${note.slug}`} className={`has-text-weight-bold ${state.currentNote?.id === note.id ? 'is-active' : ''}`}>
      <span>{note.title}</span>
      {note.children.length > 0 && <i className={'icon'} onClick={handleExpand}><FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} /></i>}
    </Link>

    <ul className="menu-list" style={{display: expanded ? "block" : "none"}}>
      {note.children && note.children.map(x => <NavLink onClick={onClick} key={x.id} note={x} level={level + 1}/>)}
    </ul>
  </li>
}

export default NavLink;