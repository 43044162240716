import * as React from "react";
import Props from "./Props";
const YouTubeImage = require('../../../../images/youtube.png');

const Component: React.FC<Props> = (props: Props) => {
  const { matches } = props.attrs;

  if (!matches){
    return <span/>;
  }

  const videoId = matches[1];

  return <iframe frameBorder="0" width="100%" height="400px" allowFullScreen
      src={`https://www.youtube.com/embed/${videoId}?modestbranding=1`}
    />
}

export default {
  View: Component,
  Regex: /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)(?<videoId>[a-zA-Z0-9_-]{11})$/i,
  Icon: () => (
    <img
      src={YouTubeImage}
      width={24}
      height={24}
    />
  )
};