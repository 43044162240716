import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/figma.png');

const Component: React.FC<Props> = (props: Props) => {
  const { matches } = props.attrs;

  if (!matches){
    return <span/>;
  }

  const url = matches[1];

  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="100%" height="400px" allowFullScreen
      src={`https://www.figma.com/embed?embed_host=outline&url=${url}`}
    />
}

export default {
  View: Component,
  Regex: new RegExp(
    "https://([w.-]+.)?figma.com/(file|proto)/([0-9a-zA-Z]{22,128})(?:/.*)?$"
  ),
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};