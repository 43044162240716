import * as React from "react";
import { Link, useNavigation } from "react-navi";
import NoteLogoPresenter from "../../../common/components/NoteLogoPresenter";
import { useFrontpage } from "../../../common/FrontpageProvider";
import { Note } from "../../../common/models";

const Box: React.FC<{note: Note}> = ({note}) => {

  const navigation = useNavigation();

  const {state, setCurrentNote} = useFrontpage();
  const {currentVariant} = state.serverState;

  const handleClick = () =>  navigation.navigate(`/${currentVariant.slug}/notes/${note.slug}`)
  
  const container = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return <div className="column is-3">
    <div className="card article-card" style={container} onClick={handleClick}>
      <div className="card-content">
        <div className="media">
          <div className="media-content" style={{textAlign: 'center'}}>
            {note.logoType !== "none" && <div className="emoji-view" style={{margin: 'auto'}}>
              <NoteLogoPresenter type={note.logoType} presentable={note.logoPresentable}/>
            </div>}

            <div className="mt-4">
              <Link className={'title is-5'} href={`/${currentVariant.slug}/notes/${note.slug}`}>{note.title}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};

const HomePage: React.FC<{}> = ({}) => {
  const {state, setCurrentNote} = useFrontpage();
  const {currentVariant} = state.serverState;

  React.useEffect(() => {
    setCurrentNote(null);
  }, []);

  const root = () => {
    return state.serverState.pagesStructure[currentVariant.id];
  }

  return <>
    {root().children.map(x => <Box key={x.id} note={x}/>)}
  </>
}

export default HomePage;