import * as React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Autosuggest from 'react-autosuggest';
import { SearchHit } from "../../models/SearchResult";
import { client } from "../../FrontpageApiClient";
import { useFrontpage } from "../../FrontpageProvider";
import { Link, useNavigation } from "react-navi";

const InstantSearchInput: React.FC<{}> = ({}) => {
  const {state, t} = useFrontpage();
  const {currentVariant} = state.serverState;

  const navigation = useNavigation();

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<Array<SearchHit>>([]);

  const getSuggestionValue = (suggestion: SearchHit) => suggestion.title;

  const renderSuggestion = (suggestion: SearchHit) => (    
    <div className="search-hit-container">
      <Link href={`/${currentVariant.slug}/notes/${suggestion.slug}`} className="suggest-item-link">
        {suggestion.title}
      </Link>

      <div className="breadcrumbs">
        <span>{suggestion.path}</span>
      </div>
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <div className="tips">
          <span className="keyboard mr-2">
            <span className="item-center">
              <span className="item">↵</span>
            </span>
          </span>

          <span>{t("to select")}</span>

          <span className="keyboard ml-4 mr-1">
            <span className="item-center">
              <span className="item">↓</span>
            </span>
          </span>

          <span className="keyboard mr-2">
            <span className="item-center">
              <span className="item">↑</span>
            </span>
          </span>

          <span>{t("to navigate")}</span>

          <span className="keyboard ml-4 mr-2">
            <span className="item-center">
              <span className="item" style={{letterSpacing: '-1px', fontSize: 10}}>esc</span>
            </span>
          </span>

          <span>{t("to close")}</span>
        </div>
      </div>
    );
  }

  const onSuggestionsFetchRequested = async ({ value }) => {
    if(value.trim().length > 0){
      try{
        const response = await client.search(currentVariant.slug, value);
        setSuggestions(response.hits || []);
      }catch(e){
        console.error(e)
      }
    }
  };

  const onSuggestionSelected = (e: React.SyntheticEvent, {suggestion, method} : {suggestion: SearchHit, method: string}) => {
    navigation.navigate(`/${currentVariant.slug}/notes/${suggestion.slug}`);
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: t("Search"),
    value: value  || '',
    name: 'q',
    onChange: (e, {newValue}) => { setValue(newValue) }
  };

  return (<div style={{width: '100%'}}>
    <div className="dropdown autosuggest" style={{width: '100%'}}>
      <div className="dropdown-trigger" style={{width: '100%'}}>
        <div className="control has-icons-left" style={{fontSize: '1.5em'}}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            renderSuggestionsContainer={renderSuggestionsContainer}
          />
          <span className="icon is-medium is-left search-icon"><FontAwesomeIcon icon={faSearch}/></span>
        </div>
      </div>
    </div>
  </div>)
}

export default InstantSearchInput;