import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/airtable.png');

const Component: React.FC<Props> = (props: Props) => {
  const { matches } = props.attrs;

  if (!matches){
    return <span/>;
  }

  const shareId = matches[1];

  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="100%" height="400px" allowFullScreen
      src={`https://airtable.com/embed/${shareId}`}
    />
}

export default {
  View: Component,
  Regex: new RegExp("https://airtable.com/(?:embed/)?(shr.*)$"),
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};