import * as React from "react";
import { NotFoundBoundary, useLoadingRoute } from "react-navi";
import LoadingBar from 'react-top-loading-bar'
import { useFrontpage } from "../../common/FrontpageProvider";
import Header from "./Header";
import Sidebar from "../../common/components/Sidebar";

interface Props {
  children: React.ReactNode,
}

const Layout: React.FC<Props> = ({ children }) => {
  const { state, t } = useFrontpage();

  const { currentNote } = state;

  const loadingBarRef = React.useRef(null)

  let loadingRoute = useLoadingRoute();

  React.useEffect(() => {
    if (loadingRoute && loadingBarRef) {
      loadingBarRef.current.continuousStart();
    }

    if (!loadingRoute && loadingBarRef) {
      loadingBarRef.current.complete();
    }
  }, [loadingRoute]);

  return <div id="simple-theme">
    <style>{`
        a, a.title {
            text-decoration: underline;
            color: ${state.serverState.project.primaryColor};
        }

        .ProseMirror a{
          color: ${state.serverState.project.primaryColor};
          text-decoration: none;
        }
        
        
        a:hover, a.title:hover {
           color: #363636;
        }
        
        a.button{
          text-decoration: none;
        }
    
        .hero.is-success, .footer {
            background-color: ${state.serverState.project.primaryColor};
            color: #fff;
        }
        
        .footer a, .footer strong{
          color: #fff;
        }
    
        .navbar{
            background-color: ${state.serverState.project.primaryColor};
            color: #fff;
        }
        
        .menu-list a{
          text-decoration: none;
        }
        .menu-list a.is-active {
            background-color: ${state.serverState.project.primaryColor}21;
            color: ${state.serverState.project.primaryColor};
            font-weight: 700;
        }
    
        .breadcrumb a{
            color: ${state.serverState.project.primaryColor};
        }
        
        .next-prev-article a:hover{
          color: ${state.serverState.project.primaryColor};
          background-color: ${state.serverState.project.primaryColor}21;
          border-radius: 6px;
          -webkit-transform: scale(1.01);
          transform: scale(1.01);
          transition: transform .3s ease;
        }
        
        #simple-theme #content .card:hover{
          border: 1px solid ${state.serverState.project.primaryColor};
          cursor: pointer;
        }
        
        #simple-theme .article-logo {
          background-color: ${state.serverState.project.primaryColor}35;
        }

        #simple-theme .navbar .dropdown-item{
          color: ${state.serverState.project.primaryColor};
        }

        #simple-theme .prev-next-card:hover{
          border: 1px solid ${state.serverState.project.primaryColor}; 
        }

        #simple-theme .prev-next-card:hover .title, #simple-theme .prev-next-card:hover .icon{
          color: ${state.serverState.project.primaryColor};
        }
        
        #simple-theme .article-card .emoji-view {
          background-color: ${state.serverState.project.primaryColor}35;
          color: ${state.serverState.project.primaryColor};
        }

        .button.is-primary.is-light {
          background-color: ${state.serverState.project.primaryColor}21;
          color: ${state.serverState.project.primaryColor};
          font-weight: 700;
        }

        .button.is-primary.is-light:hover {
          background-color: whitesmoke;
          color: ${state.serverState.project.primaryColor};
        }
      `}
    </style>

    <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />

    <Header />

    <div className="container">
      {currentNote ?
        <div className="columns is-desktop" style={{ marginTop: '0px', marginBottom: '0px' }}>
          <div className="column is-3 is-12-touch is-hidden-touch" id="sidebar">
            <Sidebar />
          </div>

          <div className="column is-9 is-12-touch" id="content">
            <div className="container frontpage-container">
              <NotFoundBoundary render={renderNotFound(t("Not Found"))}>
                {children}
              </NotFoundBoundary>
            </div>
          </div>
        </div>
        :
        <div className="columns is-desktop" style={{ marginTop: '0px', marginBottom: '0px' }}>
          <div className="column is-12-touch is-hidden-desktop is-hidden-touch" id="sidebar">
            <Sidebar />
          </div>
          <div className="column is-12" id="content" style={{ borderLeft: 'none' }}>
            <div className="container frontpage-container">
              <div className="columns is-multiline">
                <NotFoundBoundary render={renderNotFound(t("Not Found"))}>
                  {children}
                </NotFoundBoundary>
              </div>
            </div>
          </div>
        </div>
      }
    </div>

    <footer className="footer">
      <div className="container level">
        <div className="level-left">
          <div className="level-item">
            <p>
              <a className="navbar-item" style={{ textDecoration: 'none', fontSize: '18px', fontWeight: 'bold', color: 'white', filter: 'grayscale(100%)' }} href="#">
                Copyright &copy; <span className="ml-1">{state.serverState.project.name}</span>
              </a>
            </p>
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <p>
              <strong>{t("Powered by")}</strong> <a href="https://wannadocs.com" target='_blank'>Wannadocs</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
}

function renderNotFound(text: string) {
  return () => (
    <div className='error'>
      <h1 className="m-6 has-text-centered is-size-1 has-text-weight-semibold">404 | {text}</h1>
    </div>
  )
}

export default Layout;