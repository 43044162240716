import * as React from "react";
import translate from "../i18n";
import Note from "../models/Note";
import { ServerState } from "../props";

interface State {
  currentNote: Note;
  serverState: ServerState;
}

const FrontpageContext = React.createContext<State>({currentNote: null, serverState: null});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CURRENT_NOTE': {
      return {...state, currentNote: action.payload}
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

function FrontpageProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, {
    currentNote: null,
    serverState: window["__SUPERDOCS_SERVER_STATE__"]
  });
  
  const value = React.useMemo(() => [state, dispatch], [state])
  return <FrontpageContext.Provider value={value} {...props} />
}

interface Result {
  state: State;
  setCurrentNote: (note: Note) => void;
  t: (key: string) => string;
}

function useFrontpage(): Result {
  const context = React.useContext(FrontpageContext)
  if (!context) {
    throw new Error(`useFrontpage must be used within a FrontpageProvider`)
  }
  const [state, dispatch] = context as any;
  const setCurrentNote = (note: Note) => dispatch({type: 'SET_CURRENT_NOTE', payload: note});
  const t = (key: string) => translate(state.serverState.currentVariant?.lang, key)

  return {
    state,
    setCurrentNote,
    t
  }
}

export {useFrontpage, FrontpageProvider}