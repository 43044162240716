import { mount, route, map } from 'navi'
import * as React from 'react'
import { Suspense } from 'react'
import * as ReactDOM from 'react-dom'
import { Router, View } from 'react-navi'
import { FrontpageProvider } from '../themes/common/FrontpageProvider'
import { client } from '../themes/common/FrontpageApiClient'
import Layout from '../themes/yucrm/components/Layout'
import { HomePage, NotePage } from '../themes/yucrm/components/Pages'

const routes =
  mount({
    '/:variant': map((request, context) => {
      return route({
        view: <HomePage />,
      })
    }),
    '/:variant/notes/:slug': route({
      async getView(request) {
        const { variant, slug } = request.params;

        const note = await client.getNote(variant, slug);

        return <NotePage note={note} />
      }
    }),
  });

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('root');

  if (element) {
    ReactDOM.render(
      <Router routes={routes}>
        <FrontpageProvider>
          <Layout>
            <Suspense fallback={null}>
              <View />
            </Suspense>
          </Layout>
        </FrontpageProvider>
      </Router>,
      element)
  }
});