import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/trello.png');

const Component: React.FC<Props> = (props: Props) => {
  const { matches } = props.attrs;
  const objectId = matches[2];


  if (matches[1] === "c") {
    return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="316px" height="158px" allowFullScreen
        src={`https://trello.com/embed/card?id=${objectId}`}
        title={`Trello Card (${objectId})`}
      />
  }

  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="248px" height="185px" allowFullScreen
        src={`https://trello.com/embed/board?id=${objectId}`}
        title={`Trello Board (${objectId})`}
      />
}

export default {
  View: Component,
  Regex: /^https:\/\/trello.com\/(c|b)\/([^/]*)(.*)?$/,
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};