import * as React from "react";
import { useFrontpage } from "../../FrontpageProvider";
import NavLink from "./components/NavLink";
import InstantSearchInput from "../InstantSearchInput";
import LanguageSelector from "../LanguageSelector";

interface Props {
  hideSearch?: boolean;
}

const Sidebar: React.FC<Props> = ({hideSearch}) => {
  const {state, t} = useFrontpage();

  const {project, currentVariant, variants} = state.serverState;

  const root = () => {
    return state.serverState.pagesStructure[currentVariant.id];
  }

  const handleShowSidebar = () => {
    const el = document.getElementById("sidebar");
    if(el){
      el.classList.toggle("is-hidden-touch");
    }
  }

  return <div style={{width: '100%'}}>
    {!hideSearch && <div className="is-flex is-hidden-desktop" style={{marginBottom: 15}}>
      <InstantSearchInput />
    </div>}

    <aside className="menu sticky">
      <ul className="menu-list">
        {project.homePageUrl && <li className="is-hidden-desktop" style={{marginBottom: 15}}>
          <a href={project.homePageUrl} className="button is-light is-primary" target="_blank">{t("Home page")}</a>
        </li>}

        {variants.length > 1 && <li className="is-hidden-desktop" style={{marginBottom: 15}}>
          <LanguageSelector />
        </li>}
        {root().children.map(x => <NavLink onClick={handleShowSidebar} key={x.id} note={x} level={0}/>)}
      </ul>
    </aside>
  </div>
}

export default Sidebar;