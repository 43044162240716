import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Note from "../../models/Note";
import { Link } from "react-navi";
import { useFrontpage } from "../../FrontpageProvider";

interface Props {
 note?: Note;
}

const PrevNext: React.FC<Props> = ({note}) => {
  const {state, t} = useFrontpage();

  const {currentVariant} = state.serverState;

  return <div className="prev-next">
    {note.previous && <Link href={`/${currentVariant.slug}/notes/${note.previous.slug}`} className="prev-next-card">
      <div>
        <FontAwesomeIcon className="icon" icon={faChevronLeft}/>
      </div>
      <div className="previous">
        <div className="has-text-grey is-size-6">{t("Previous")}</div>
        <div className="title is-6 has-text-right">{note.previous.title}</div>
      </div>
    </Link>}

    {note.next && <Link href={`/${currentVariant.slug}/notes/${note.next.slug}`} className="prev-next-card">
      <div className="next">
        <div className="has-text-grey is-size-6">{t("Next")}</div>
        <div className="title is-6 has-text-left">{note.next.title}</div>
      </div>
      <div>
        <FontAwesomeIcon className="icon" icon={faChevronRight}/>
      </div>
    </Link>}
  </div>
};

export default PrevNext;