import * as React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import classNames from "classnames";
import {useClickAway} from 'react-use';
import { setCookie } from "tiny-cookie";
import { useFrontpage } from "../../FrontpageProvider";

const LanguageDropdown: React.FC<{}> = ({}) => {

  const {state} = useFrontpage();

  const {currentVariant, variants} = state.serverState;

  const [active, setActive] = useState(false)
  const dropDownClasses = classNames({'dropdown': true, 'is-active': active, 'is-right': true});

  const ref = React.useRef(null);

  useClickAway(ref, () => {
    setActive(false);
  });

  const changeLanguage = (slug: string) => {
    setCookie('variant', slug, { expires: 365 });
    window.location.href = '/';
  }

  return (
    <div className={dropDownClasses} ref={ref}>
      <div className="dropdown-trigger">
        <button className="button is-light" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => { setActive(!active); }}>
          <span>{currentVariant.title}</span>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {variants.map(x => <a key={x.id} onClick={(e) => { e.preventDefault(); changeLanguage(x.slug); } } href="#" className="dropdown-item">{x.title}</a>)}
        </div>
      </div>
    </div>
  )
}

export default LanguageDropdown;