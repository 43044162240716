import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/github-gist.png');

const Component: React.FC<Props> = (props: Props) => {
  const { matches } = props.attrs;

  const id = () => {
    const gistUrl = new URL(props.attrs.href);
    return gistUrl.pathname.split("/")[2];
  }

  const contentHtml = () => {
    const gistLink = `https://gist.github.com/${id()}.js`;
    const gistScript = `<script type="text/javascript" src="${gistLink}"></script>`;
    const styles =
      "<style>*{ font-size:12px; } body { margin: 0; } .gist .blob-wrapper.data { max-height:350px; overflow:auto; }</style>";

    return `<html><head><base target="_parent">${styles}</head><body>${gistScript}</body></html>`;
  };

  if (!matches){
    return <span/>;
  }

  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="100%" height="420px" allowFullScreen
    src={"data:text/html,"+encodeURIComponent(contentHtml())}
    id={`gist-${id()}`}
    title={`Github Gist (${id()})`}
  />
}

export default {
  View: Component,
  Regex: new RegExp("^https://gist.github.com/([a-z\\d](?:[a-z\\d]|-(?=[a-z\\d])){0,38})/(.*)$"),
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};