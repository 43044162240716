import { Note } from "./models";
import { SearchResult } from "./models/SearchResult";
import { NotFoundError } from 'navi'

export interface IResponse {
  ok: boolean
  data: any
  error?: string,
  message?: string
}

class ApplicationError extends Error {}
class ValidationError extends ApplicationError {}

class FrontpageApiClient {
  baseUrl: string

  constructor(baseUrl = '/') {
    this.baseUrl = baseUrl;
  }

  async request(path: string, method: string, body: Object | FormData = {}): Promise<IResponse> {

    let response = null;
    if (body instanceof FormData) {
      response = await fetch(this.baseUrl + path, {
        method: method,
        body: body,
        cache: "no-cache"
      });
    }else{
      response = await fetch(this.baseUrl + path, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          pragma: "no-cache"
        },
        method: method,
        body: method === "GET" || method === "HEAD" ? null : JSON.stringify(body),
        cache: "no-cache"
      });
    }

    const success = response.status >= 200 && response.status < 300;

    if (success) {
      return response.json();
    }else{
      let parsed = {ok: false, data: null, message: '', error: ''}
      try {
        parsed = await response.json();
        throw new ValidationError(parsed.message);
      } catch (e) {
        throw new ApplicationError(e.message)
      }
    }
  }

  async get(path: string): Promise<IResponse> {
    return this.request(path, 'GET');
  }

  async post(path: string, body: Object | FormData = {}): Promise<IResponse> {
    return this.request(path, 'POST', body);
  }

  async getNote(variant: string, slug: string): Promise<Note> {
    try{
    const response = await this.get(`${variant}/notes/${slug}.json`);

    return response.data as Note;
    }catch(e){
      console.log(e)
      throw new NotFoundError();
    }
  }

  async trackView(id: string): Promise<Boolean> {
    const response = await this.post(`analytics/view`, {id: id});

    return response.ok;
  }

  async trackFeedback(id: string, vote: string): Promise<Boolean> {
    const response = await this.post(`analytics/feedback`, {id: id, vote: vote});

    return response.ok;
  }

  async search(variant: string, query: string): Promise<SearchResult> {
    const response = await this.get(`${variant}/search?${new URLSearchParams({q: query})}`);

    return response.data as SearchResult;
  }
}

export default FrontpageApiClient;
export const client = new FrontpageApiClient();