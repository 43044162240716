import russian from "./russian";

const translate = (locale: string, key: string): string => {
  if(locale === "ru"){
    return russian[key] || key;
  }

  return key;
}

export default translate;