import * as React from "react";
import { Link } from "react-navi";
import { useFrontpage } from "../../../common/FrontpageProvider";
import InstantSearchInput from "../../../common/components/InstantSearchInput";
import LanguageDropdown from "../../../common/components/LanguageDropdown";

const Header: React.FC<{}> = ({}) => {
  const {state, t} = useFrontpage();

  const {project, currentVariant, variants} = state.serverState;

  const handleShowSidebar = () => {
    const el = document.getElementById("sidebar");
    if(el){
      el.classList.toggle("is-hidden-touch");
    }
  }

  return <section className="hero is-success is-medium">
  <div className="hero-head">
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" style={{textDecoration: 'none', fontSize: '2em', fontWeight: 'bold', color: 'white'}} href={`/${currentVariant.slug}`}>
            {project.logoUrl && <img src={project.logoUrl} style={{marginRight: '5px'}}/> }
            <span className="ml-1">{project.name}</span>
          </Link>

          <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={handleShowSidebar}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-menu">
          <div className="navbar-item column is-6">
            <InstantSearchInput />
          </div>

          <div className="navbar-end">
            {project.homePageUrl && <div className="navbar-item">
              <a href={project.homePageUrl} className="button is-light" target="_blank">{t("Home page")}</a>
            </div>}

            {variants.length > 1 && <div className="navbar-item">
              <LanguageDropdown />
            </div>}
          </div>
        </div>
      </div>
    </nav>
  </div>
</section>
}

export default Header;