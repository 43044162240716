import * as React from "react";
import { useFrontpage } from "../../../common/FrontpageProvider";
import Note from "../../../common/models/Note";
import NoteView from "../../../common/components/NoteView";
import { client } from "../../../common/FrontpageApiClient";

interface Props {
  note: Note;
}

const NotePage: React.FC<Props> = ({note}) => {
  const {state, setCurrentNote} = useFrontpage();

  React.useEffect(() => {
    setCurrentNote(note);
    client.trackView(note.id);
    document.title = `${note.title} | ${state.serverState.project.name}`;
  }, [note.id])

  return <div>
    {state.currentNote && <NoteView note={state.currentNote}/>}
  </div>
}

export default NotePage;
