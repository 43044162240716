import * as React from "react";

const NoteLogoPresenter: React.FC<{
  type: string,
  presentable: string
}> = ({type, presentable}) => {
  
  if(type === "emoji") return <div className="logo-presentable">{presentable}</div>;

  if(type === "file") return <img className="logo-presentable" src={presentable} />

  return <></>
}

export default NoteLogoPresenter;