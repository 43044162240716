import * as React from "react";
import { Link } from "react-navi";
import { useFrontpage } from "../../FrontpageProvider";
import Note from "../../models/Note";
import Editor from "@andreyblinov/rich-markdown-editor";
import PrevNext from "./PrevNext";
import { DiscussionEmbed } from 'disqus-react';
import Feedback from "./Feedback";
import NoteLogoPresenter from "../NoteLogoPresenter";
import EMBEDS from "app/components/Note/components/embeds";

interface Props {
  note: Note;
}

const LogoView: React.FC<{
  type: string,
  presentable: string
}> = ({type, presentable}) => {

  if(type === "emoji") return <div className="article-logo emoji">
    <NoteLogoPresenter type={type} presentable={presentable}/>
  </div>;

  if(type === "file") return <div className="article-logo file">
    <NoteLogoPresenter type={type} presentable={presentable}/>
  </div>

  return <></>
}

const NoteView: React.FC<Props> = ({note}) => {
  const {state, t} = useFrontpage();

  const {currentVariant, project} = state.serverState;

  const isEmpty = () => note.text === "" || note.text === "\\\n" || note.text === "\\"

  const renderContent = () => {
    if(note.isTableOfContents && isEmpty()) return <></>

    return isEmpty() ? <div className="empty-page">{t("This page is empty.")}</div> : <Editor embeds={EMBEDS} disableExtensions={["emoji"]} value={note.text} defaultValue={note.text} readOnly={true} onChange={() => {}}/>
  }

  return <div id="article-layout">
    <nav className="breadcrumb has-arrow-separator is-medium" aria-label="breadcrumbs">
      <ul>
        {note.breadcrumbs && note.breadcrumbs.map(x =>
          <li key={x.id}>
            <Link href={`/${currentVariant.slug}/notes/${x.slug}`}>{x.title}</Link>
          </li>)}
      </ul>
    </nav>

    <LogoView type={note.logoType} presentable={note.logoPresentable}/>

    <h1 className="title is-1">
      {note.title}
    </h1>

    <div className="content">
      {renderContent()}
    </div>

    {note.isTableOfContents && <ul className="categories-list">
      {note.children.map((x) =>
        <li key={x.id}>
          <Link href={`/${currentVariant.slug}/notes/${x.slug}`}>{x.title}</Link>
        </li>
      )}
    </ul>}

    <PrevNext note={note} />
    <Feedback note={note}/>


    {project.disqusEnabled && <DiscussionEmbed
        shortname={project.disqusShortname}
        config={
          {
            url: window.location.href,
            identifier: note.id,
            title: note.title
          }
        }
      />}
  </div>
}

export default NoteView;
