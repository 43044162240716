import * as React from "react";
import { setCookie } from "tiny-cookie";
import { useFrontpage } from "../../FrontpageProvider";

const LanguageSelector: React.FC<{}> = ({}) => {

  const {state} = useFrontpage();

  const {currentVariant, variants} = state.serverState;


  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCookie('variant', e.currentTarget.value, { expires: 365 });
    window.location.href = '/';
  }

  return (
    <div className="select is-flex">
      <select style={{width: '100%'}} onChange={changeLanguage} value={currentVariant.slug}>
        {variants.map(x => <option value={x.slug} key={x.id}>{x.title}</option>)}
      </select>
    </div>
  )
}

export default LanguageSelector;