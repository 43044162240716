import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/vimeo.png');

const Component: React.FC<Props> = (props: Props) => {
  const { matches } = props.attrs;
  const videoId = matches[4];

  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="100%" height="400px" allowFullScreen
      src={`https://player.vimeo.com/video/${videoId}?byline=0`}
    />
}

export default {
  View: Component,
  Regex: /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/,
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};