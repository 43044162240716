
const russian = {
  "How can we help?": "Как мы можем помочь?",
  "Search": "Поиск",
  "Top articles": "Популярные статьи",
  "Powered by": "Работает на",
  "Next": "Следующая",
  "Previous": "Предыдущая",
  "Last updated": "Обновлено",
  "This page is empty.": "Пустая страница.",
  "Was this page helpful?": "Была ли эта страница полезной?",
  "Thank you for your help.": "Спасибо за помощь.",
  "Not Found": "Не найдено",
  "to select": "выбрать",
  "to navigate": "навигация",
  "to close": "закрыть",
  "Home page": "Домашняя страница"
}

export default russian;