import * as React from "react";
import Props from "./Props";
const Icon = require('../../../../images/codepen.png');

const Component: React.FC<Props> = (props: Props) => {
  const normalizedUrl = props.attrs.href.replace(/\/pen\//, "/embed/");

  return <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" frameBorder="0" width="100%" height="400px" allowFullScreen
      src={normalizedUrl}
    />
}

export default {
  View: Component,
  Regex: new RegExp("^https://codepen.io/(.*?)/(pen|embed)/(.*)$"),
  Icon: () => (
    <img
      src={Icon}
      width={24}
      height={24}
    />
  )
};